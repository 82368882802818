import React from 'react';
import { Button, Icon } from '@nimbus-ds/components';
import { LifeRingIcon } from '@nimbus-ds/icons';
import { t } from 'i18next';
import { getStoreInfo } from '@tiendanube/nexo/helpers';
import nexo from '../../nexoClient';

const HelpButton: React.FC = () => {
  const handleClick = async () => {
    const store = await getStoreInfo(nexo);
    window.open(`https://huapps.com.br/ajuda/b2b/${store.language}`, '_blank');
  };
  return (
    <Button onClick={handleClick} appearance="primary">
      <Icon source={<LifeRingIcon />} color="currentColor" />
      {t('general.help')}
    </Button>
  );
};

export default HelpButton;
