import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Box, Icon, Input, Label, Tooltip } from '@nimbus-ds/components';
import { QuestionCircleIcon } from '@nimbus-ds/icons';

interface InputColorProps {
  name: string;
  value: string;
  label?: string;
  labelHelpText?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: ((event: ChangeEvent<HTMLInputElement>) => void) | null;
}

const InputColor: React.FC<InputColorProps> = ({
  name,
  value,
  label,
  labelHelpText,
  disabled,
  required,
  onChange,
}: InputColorProps) => {
  const [color, setColor] = useState<string>(value);
  const inputColorRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setColor(value);
    if (name === event.target.name && onChange !== null) {
      onChange(event);
    }
  };

  return (
    <Box display="flex" alignItems="flex-start" gap="2">
      <Box
        display="block"
        minWidth="56px"
        height="56px"
        borderRadius="2"
        boxShadow="2"
        overflow="hidden"
        style={{ backgroundColor: color }}
        onClick={() =>
          inputColorRef.current ? inputColorRef.current.click() : null
        }
      >
        <div style={{ background: color, width: '100%', height: '100%' }}></div>
      </Box>
      <Box display="flex" flexDirection="column" gap="2">
        {label && label !== '' ? (
          <Label>
            {label}
            {labelHelpText && labelHelpText !== '' ? (
              <Tooltip content={labelHelpText} position="right">
                <Icon
                  color="primary-textLow"
                  source={<QuestionCircleIcon size={12} />}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        <Box position="relative">
          <Box position="absolute" zIndex="100">
            <Input
              ref={inputColorRef}
              disabled={disabled || false}
              required={required || false}
              type="color"
              value={value}
              onChange={handleChange}
              name={name}
            />
          </Box>
          <Box position="relative" zIndex="200">
            <Input
              type="text"
              disabled={disabled || false}
              required={required || false}
              value={value}
              onChange={handleChange}
              onFocus={() =>
                inputColorRef.current ? inputColorRef.current.click() : null
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InputColor;
