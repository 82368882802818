import { ChangeEvent, useEffect, useState } from 'react';

import {
  Icon,
  Box,
  Link,
  Tooltip,
  Pagination,
  Text,
  useToast,
  Sidebar,
  Button,
  Input,
  Tabs,
} from '@nimbus-ds/components';
import { DataList, EmptyMessage, Layout, Page } from '@nimbus-ds/patterns';
import emptyCustomers from '../../lib/assets/empty-customers.png';
import {
  ChevronDownIcon,
  DownloadIcon,
  ExternalLinkIcon,
  RedoIcon,
} from '@nimbus-ds/icons';
import { goTo, navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import apiIntance from '../../utils/apiUtils';
import {
  ICustomerList,
  IDocumentCustomerInfo,
} from '../../lib/interfaces/customer.interfaces';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatUtils';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces';
import { ConfigButton } from '../../components';
import HelpButton from '../../components/HelpButton/HelpButton';
import { useHistory } from 'react-router-dom';

function PageMain() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const { push } = useHistory();

  interface ICustomerData {
    id: number;
    name: string;
    email: string;
    date: string;
    extra: { [key: string]: string };
  }

  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDocumentInfo, setLoadingDocumentInfo] = useState(false);
  const [loadingClone, setLoadingClone] = useState(false);
  const [documentInfo, setDocumentInfo] =
    useState<IDocumentCustomerInfo | null>(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [customersData, setCustomersData] = useState<ICustomerData[]>([]);
  const [customersMeta, setCustomersMeta] = useState<IPaginateMeta>();
  const [customDetailInfo, setCustomDetailInfo] = useState<{
    [key: string]: any;
  }>({});
  const [customFields, setCustomFields] = useState<
    { slug: string; name: string }[]
  >([]);

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  useEffect(() => {
    navigateHeaderRemove(nexo);
    getCustomFormFields();
    getCustomers();
    getDocumentsInfo();
  }, []);

  const handleShowDetails = (extraData: { [key: string]: any }) => {
    setOpenDetail(true);
    setCustomDetailInfo(extraData);
  };

  const getCustomFormFields = async () => {
    try {
      const result = await apiIntance.get(`/forms/custom-fields-name`);
      setCustomFields(result.data as { slug: string; name: string }[]);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomers = async (page = 1, q = '') => {
    try {
      setLoadingData(true);
      const result = await apiIntance.get(`/customers?page=${page}&q=${q}`);
      const customerList = result.data as ICustomerList;
      setCustomersMeta(customerList.meta);
      const customerListToTable: ICustomerData[] = [];
      for (let x = 0; x < customerList.result.length; x++) {
        try {
          const item = customerList.result[x];
          const json = JSON.parse(item.json);
          customerListToTable.push({
            id: item.external_id,
            name: json.name,
            email: json.email,
            date: item.createdAt ? item.createdAt : '---',
            extra: json.extra || {},
          });
        } catch (error) {
          console.log(error);
        }
      }
      setCustomersData(customerListToTable);
      setLoadingData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resetSearch = () => {
    setSearchQuery('');

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      getCustomers(customersMeta?.page || 1);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchQuery(value);

    // Limpa o timeout anterior, se existir
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      setSearchLoading(true);
      getCustomers(customersMeta?.page || 1, value);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };

  const downloadReport = async () => {
    try {
      setLoadingDownload(true);
      const result = await apiIntance.get(`/customers/report`, {
        responseType: 'blob',
      });

      // Criando um URL para o Blob
      const url = window.URL.createObjectURL(new Blob([result.data]));

      // Criando um link para download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customers_report.xlsx'); // Ou outro nome de arquivo desejado

      // Adicionando o link ao DOM e clicando nele
      document.body.appendChild(link);
      link.click();

      // Limpando e removendo o link do DOM
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(url);

      addToast({
        id: 'success' + new Date().toISOString(),
        type: 'success',
        text: t('app.page.download_report.success'),
      });

      setLoadingDownload(false);
    } catch (error) {
      console.log(error);
      addToast({
        id: 'error' + new Date().toISOString(),
        type: 'danger',
        text: t('app.page.download_report.error'),
      });
    }
  };

  const handleShowCustomFields = async (customer_id: number) => {
    try {
      const result = await apiIntance.get(
        `/forms/custom-fields/${customer_id}`,
      );
      const data = result.data as any;

      const extraData: { [key: string]: any } = {};
      if (data.custom_fields.length) {
        for (let x = 0; x < data.custom_fields.length; x++) {
          const field = data.custom_fields[x];
          extraData[field.description] = field.value;
        }
        handleShowDetails(extraData);
      } else {
        addToast({
          id: 'error' + new Date().toISOString(),
          type: 'danger',
          text: t('app.page.show_details_custom_fields_error'),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDocumentsInfo = async () => {
    try {
      setLoadingDocumentInfo(true);
      const result = await apiIntance.get(`/document-customers/info`);
      setDocumentInfo(result.data as IDocumentCustomerInfo);
    } catch (error) {
      console.log(error);
    }
    setLoadingDocumentInfo(false);
  };

  const cloneDocumentCustomers = async () => {
    try {
      setLoadingClone(true);
      await apiIntance.post(`/document-customers/clone`);
      addToast({
        id: 'success' + new Date().toISOString(),
        type: 'success',
        text: t('MainPage.Tabs.Tab2.clone_success_alert'),
      });
    } catch (error) {
      console.error(`cloneDocumentCustomers error ==>`, error);
      addToast({
        id: 'danger' + new Date().toISOString(),
        type: 'danger',
        text: t('MainPage.Tabs.Tab2.clone_error_alert'),
      });
    }
    setLoadingClone(false);
  };

  return (
    <>
      <Page>
        <Page.Header
          title={t('app.page.title')}
          buttonStack={
            <>
              <HelpButton />
              <ConfigButton />
            </>
          }
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <>
                {customersData.length || loadingData || searchLoading ? (
                  <>
                    <Box display="flex" flexDirection="column" gap="2">
                      <Box display="flex" gap="1">
                        <Input.Search
                          placeholder={`${t(
                            'app.page.list.search.query_placeholder',
                          )}`}
                          name="search-query"
                          onChange={handleChangeSearch}
                          value={searchQuery}
                        />
                      </Box>
                    </Box>
                    <DataList>
                      <Tabs fullWidth>
                        <Tabs.Item label={t('MainPage.Tabs.Tab1.title')}>
                          {loadingData ? (
                            <>
                              <DataList.Row gap="1">
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Text.Skeleton />
                                  <Text.Skeleton />
                                </Box>
                                <Box display="grid" gap="2">
                                  <Text.Skeleton />
                                </Box>
                                <Link.Skeleton />
                              </DataList.Row>
                              <DataList.Row gap="1">
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Text.Skeleton />
                                  <Text.Skeleton />
                                </Box>
                                <Box display="grid" gap="2">
                                  <Text.Skeleton />
                                </Box>
                                <Link.Skeleton />
                              </DataList.Row>
                              <DataList.Row gap="1">
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Text.Skeleton />
                                  <Text.Skeleton />
                                </Box>
                                <Box display="grid" gap="2">
                                  <Text.Skeleton />
                                </Box>
                                <Link.Skeleton />
                              </DataList.Row>
                            </>
                          ) : customersData.length ? (
                            customersData.map((item, index) => (
                              <DataList.Row key={index} gap="1">
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  {item.id === -1 ? (
                                    <Text>{`Ceos Digital`}</Text>
                                  ) : (
                                    <Tooltip
                                      content={t(
                                        'app.page.show_customer_details',
                                      )}
                                      position="right"
                                    >
                                      <Link
                                        as="button"
                                        onClick={() =>
                                          goTo(nexo, `/customers/${item.id}`)
                                        }
                                        appearance="primary"
                                      >
                                        #{item.id}
                                      </Link>
                                    </Tooltip>
                                  )}
                                  <Text>{formatDate(item.date)}</Text>
                                </Box>
                                <Box display="grid" gap="2">
                                  <Text fontWeight="bold">
                                    {item.name} ({item.email})
                                  </Text>
                                </Box>
                                {item.id > -1 &&
                                item.extra &&
                                Object.keys(item.extra).length ? (
                                  <Link
                                    onClick={() =>
                                      handleShowDetails(item.extra)
                                    }
                                  >
                                    {t('app.page.show_details_custom_fields')}
                                    <Icon
                                      color="currentColor"
                                      source={<ChevronDownIcon />}
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    textDecoration="none"
                                    onClick={() =>
                                      handleShowCustomFields(item.id)
                                    }
                                  >
                                    {t('app.page.show_details_custom_fields')}
                                    <Icon
                                      color="currentColor"
                                      source={<ChevronDownIcon />}
                                    />
                                  </Link>
                                )}
                              </DataList.Row>
                            ))
                          ) : searchLoading ? (
                            <Box paddingTop="5" paddingBottom="5">
                              <EmptyMessage
                                title={t('app.page.empty_title')}
                                text={`${t('app.page.empty_message')}`}
                                actions={
                                  <Link
                                    as="button"
                                    appearance="primary"
                                    onClick={resetSearch}
                                  >
                                    <Icon
                                      color="currentColor"
                                      source={<RedoIcon />}
                                    />
                                    Limpar filtro
                                  </Link>
                                }
                              />
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Tabs.Item>
                        <Tabs.Item label={t('MainPage.Tabs.Tab2.title')}>
                          <Box display="grid" gap="6" padding="4">
                            <Text>
                              {t('MainPage.Tabs.Tab2.description')}
                              <Link
                                as="button"
                                onClick={() => push('/custom-form')}
                              >
                                {t('MainPage.Tabs.Tab2.cta_description_text')}
                              </Link>
                            </Text>
                            {loadingDocumentInfo ? (
                              <></>
                            ) : (
                              <Layout columns="3">
                                <Layout.Section>
                                  <Box
                                    display="grid"
                                    gap="1"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderColor="neutral-interactive"
                                    borderStyle="solid"
                                    borderWidth="1"
                                    borderRadius="2"
                                    width="100%"
                                    padding="4"
                                    boxSizing="border-box"
                                  >
                                    <Text fontSize="caption">
                                      Clientes registrados
                                    </Text>
                                    <Text
                                      fontSize="highlight"
                                      color="primary-interactive"
                                    >
                                      {documentInfo?.total || 0}
                                    </Text>
                                  </Box>
                                </Layout.Section>
                                <Layout.Section>
                                  <Box
                                    display="grid"
                                    gap="1"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderColor="neutral-interactive"
                                    borderStyle="solid"
                                    borderWidth="1"
                                    borderRadius="2"
                                    width="100%"
                                    padding="4"
                                    boxSizing="border-box"
                                  >
                                    <Text fontSize="caption">
                                      Documentos inválidos
                                    </Text>
                                    <Text
                                      fontSize="highlight"
                                      color="danger-interactive"
                                    >
                                      {documentInfo?.invalidDocuments.total ||
                                        0}
                                    </Text>
                                  </Box>
                                </Layout.Section>
                                <Layout.Section>
                                  <Box
                                    display="grid"
                                    gap="1"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderColor="neutral-interactive"
                                    borderStyle="solid"
                                    borderWidth="1"
                                    borderRadius="2"
                                    width="100%"
                                    padding="4"
                                    boxSizing="border-box"
                                  >
                                    <Text fontSize="caption">
                                      Documentos duplicados
                                    </Text>
                                    <Text
                                      fontSize="highlight"
                                      color="warning-interactive"
                                    >
                                      {documentInfo?.duplicateDocuments.total ||
                                        0}
                                    </Text>
                                  </Box>
                                </Layout.Section>
                              </Layout>
                            )}

                            <Button
                              appearance="neutral"
                              onClick={cloneDocumentCustomers}
                              disabled={loadingClone}
                            >
                              {t('MainPage.Tabs.Tab2.clone_clients')}
                            </Button>
                          </Box>
                        </Tabs.Item>
                      </Tabs>
                    </DataList>
                  </>
                ) : (
                  <EmptyMessage
                    title={t('app.page.empty_title')}
                    text={`${t('app.page.empty_message')}`}
                    illustration={
                      <img
                        width={600}
                        alt={`${t('app.page.empty_title')}`}
                        src={emptyCustomers}
                      />
                    }
                  />
                )}

                {customersData.length ? (
                  <Box
                    display="flex"
                    gap="2"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {customersMeta && customersMeta.pages > 1 ? (
                      <Pagination
                        activePage={customersMeta?.page}
                        onPageChange={getCustomers}
                        pageCount={customersMeta?.pages}
                      />
                    ) : (
                      <Box></Box>
                    )}
                    <Button
                      appearance="neutral"
                      disabled={loadingDownload}
                      onClick={downloadReport}
                    >
                      {t('app.page.download_report.cta')}
                      <Icon color="currentColor" source={<DownloadIcon />} />
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </>
            </Layout.Section>
          </Layout>
        </Page.Body>
        <Sidebar
          padding="base"
          open={openDetail}
          onRemove={() => setOpenDetail(false)}
        >
          <Sidebar.Header title={`${t('app.page.modal.title')}`} />
          <Box display="grid" padding="none" gap="2" width="100%">
            {Object.keys(customDetailInfo).map((key, i) => (
              <Box
                width="100%"
                display="flex"
                flexWrap="nowrap"
                borderColor="neutral-interactive"
                borderWidth="1"
                borderRadius="2"
                borderStyle="solid"
                gap="3"
                padding="2"
                key={i}
              >
                <Box minWidth="30%">
                  <Text
                    as="p"
                    color="neutral-textHigh"
                    textAlign="right"
                    fontWeight="bold"
                  >
                    {key}:
                  </Text>
                </Box>
                <Box>
                  <Text as="span">
                    {customDetailInfo[`${key}`].indexOf('https://') === 0 ||
                    customDetailInfo[`${key}`].indexOf('http://') === 0 ? (
                      <Link
                        as="a"
                        href={customDetailInfo[`${key}`]}
                        target="_blank"
                        appearance="primary"
                      >
                        {t(`general.open`)}{' '}
                        <Icon
                          source={<ExternalLinkIcon size="small" />}
                          color="currentColor"
                        />
                      </Link>
                    ) : (
                      customDetailInfo[`${key}`]
                    )}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Sidebar>
      </Page>
    </>
  );
}

export default PageMain;
