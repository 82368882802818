import React from 'react';
import { Icon, Label, Tooltip } from '@nimbus-ds/components';
import { QuestionCircleIcon } from '@nimbus-ds/icons';

interface LabelHelpProps {
  htmlFor?: string;
  label: string;
  helpText: string;
}

const LabelHelp: React.FC<LabelHelpProps> = ({
  htmlFor,
  label,
  helpText,
}: LabelHelpProps) => {
  return (
    <Label htmlFor={htmlFor}>
      {label}
      <Tooltip position="right" content={helpText}>
        <Icon
          color="primary-textLow"
          source={<QuestionCircleIcon size={12} />}
        />
      </Tooltip>
    </Label>
  );
};

export default LabelHelp;
