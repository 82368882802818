export interface IGroupRole {
  _id?: string;
  store_id: number; // ID da loja associada ao campo
  script_external_id: number | null;
  active: boolean; // Indica se o formulário está ativo ou não
  hide_products_visitor: boolean;
  hide_products_price_visitor: boolean;
  page_visitor_redirect: string;
  message_qty_min_cart: string;
  message_value_min_cart: string;
  message_cart_background_color: string;
  message_cart_text_color: string;
  count_groups?: number;
  show_register_login_cta: boolean;
  register_cta_text: string;
  login_cta_text: string;
  discount_apply?: boolean;
  external_discount_id?: string;
  discount_default_message?: string;
  createdAt?: string;
  updatedAt?: string;
}

export const empytGroupRole: IGroupRole = {
  store_id: -1,
  script_external_id: null,
  active: false,
  hide_products_visitor: false,
  hide_products_price_visitor: false,
  page_visitor_redirect: '/account/login',
  message_qty_min_cart: 'A quantidade minima de compra é de {qty} itens',
  message_value_min_cart:
    'O valor mínimo de compra é de {value} sem considerar o custo de frete',
  message_cart_background_color: '#333333',
  message_cart_text_color: '#ffffff',
  show_register_login_cta: false,
  register_cta_text: 'Cadastre-se',
  login_cta_text: 'Entrar',
  discount_apply: false,
  external_discount_id: '',
  discount_default_message: '',
};
