import type { IPaginateMeta } from './paginate.interfaces';

export interface ICustomerGroupRelation {
  _id?: string;
  store_id: number;
  external_customer_id: number;
  external_customer_name: string;
  external_customer_email: string;
  customer_group_id: string;
}

export interface ICustomerGroupRelationList {
  meta: IPaginateMeta;
  result: ICustomerGroupRelation[];
}

export interface ICustomerGroup {
  _id?: string;
  store_id: number; // ID da loja associada ao campo
  active: boolean; // Indica se o formulário está ativo ou não

  name: string;
  display_name: string;
  icon?: string;
  minimum_order: number;
  minimum_first_order: number;
  discount_shop: number;
  minimum_items_cart: number;
  ignore_products?: string;

  default_role: boolean;
  customers_count?: number;

  createdAt?: string;
  updatedAt?: string;
}

export const empytCustomerGroup: ICustomerGroup = {
  active: false,
  store_id: -1,
  name: '',
  display_name: '',
  icon: '',
  minimum_order: 0,
  minimum_first_order: 0,
  discount_shop: 0,
  default_role: false,
  minimum_items_cart: 0,
  ignore_products: '',
};
