import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Icon,
  Tag,
  Text,
  Title,
} from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';

interface ConfigCardProps {
  title: string;
  message: string;
  tags: string[];
  cta_text: string;
  href: string;
  ToggleComponent: React.ReactNode;
}

const ConfigCard: React.FC<ConfigCardProps> = ({
  title,
  message,
  tags,
  cta_text,
  href,
  ToggleComponent,
}: ConfigCardProps) => {
  const { push } = useHistory();
  const handleGoToPage = (href: string) => push(href);

  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h3">{title}</Title>
          {ToggleComponent}
        </Box>
      </Card.Header>
      <Card.Body>
        <Text fontSize="base" lineHeight="base" textAlign="left">
          {message}
        </Text>
        <Box marginTop="2">
          <Box display="flex" flexDirection="row" gap="2" flexWrap="wrap">
            {tags.map((tag, index) => {
              if (tag !== '') {
                return (
                  <Tag key={index} appearance="primary">
                    {tag}
                  </Tag>
                );
              }
            })}
          </Box>
        </Box>
      </Card.Body>
      <Card.Footer>
        <Button appearance="neutral" onClick={() => handleGoToPage(href)}>
          {cta_text}
          <Icon color="currentColor" source={<EditIcon />} />
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ConfigCard;
