import React, { ChangeEvent, useEffect, useState } from 'react';

import { ConfigCard } from '../../components';
import nexo from '../../nexoClient';
import apiIntance from '../../utils/apiUtils';

import { Page, Layout } from '@nimbus-ds/patterns';
import { Box, Toggle, useToast } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import { IForm, empytForm } from '../../lib/interfaces/form.interfaces';
import {
  IGroupRole,
  empytGroupRole,
} from '../../lib/interfaces/group-role.interfaces';
import HelpButton from '../../components/HelpButton/HelpButton';

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();

  const [myForm, setMyForm] = useState<IForm | null>(null);
  const [myGroupRole, setMyGroupRole] = useState<IGroupRole | null>(null);
  const [loadingFormData, setLoadingFormData] = useState(true);
  const [loadingGroupData, setLoadingGroupData] = useState(true);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });

    const fetchData = async () => {
      try {
        const result = await apiIntance.get('/forms');
        if (result.data !== null) {
          setMyForm({ ...result.data });
        } else {
          setMyForm({ ...empytForm });
        }
      } catch (error) {
        setMyForm(null);
      }
      setLoadingFormData(false);

      try {
        const result = await apiIntance.get('/group-roles/details');
        if (result.data !== null) {
          setMyGroupRole({ ...result.data });
        } else {
          setMyGroupRole({ ...empytGroupRole });
        }
      } catch (error) {
        setMyGroupRole(null);
      }
      setLoadingGroupData(false);
    };

    fetchData();
  }, []);

  const getCustomFields = () => {
    let count = 0;
    if (myForm !== null) {
      for (let x = 0; x < myForm.fields.length; x++) {
        if (myForm.fields[x].custom_field) {
          count++;
        }
      }
    }
    return count;
  };

  const getFormType = () => {
    let result = t('app.config.page.cards.custom_form.type_manual');
    if (myForm !== null) {
      for (let x = 0; x < myForm.fields.length; x++) {
        if (myForm.fields[x].name === 'password' && myForm.fields[x].active) {
          result = t('app.config.page.cards.custom_form.type_automatic');
        }
      }
    }
    return result;
  };

  const handleToggleGroupStatus = async (e: ChangeEvent<HTMLInputElement>) => {
    if (myGroupRole !== null) {
      setLoadingGroupData(true);
      const { checked } = e.target;
      const status = checked ? 'start' : 'stop';
      try {
        const result = await apiIntance.post(`/group-roles/${status}`);
        if (result.status === 200) {
          setMyGroupRole((prevState) => ({
            ...(prevState as IGroupRole),
            active: checked,
          }));
          addToast({
            id: 'group-status-success' + new Date().toISOString(),
            type: 'success',
            text: t(
              `app.config.page.cards.customers_group.toast_toogle_status_${status}_success`,
            ),
          });
        }
      } catch (error) {
        e.target.checked = !checked;
        addToast({
          id: 'group-status-error' + new Date().toISOString(),
          type: 'danger',
          text: t(
            `app.config.page.cards.customers_group.toast_toogle_status_${status}_error`,
          ),
        });
      }
      setLoadingGroupData(false);
    }
  };

  const handleToggleFormStatus = async (e: ChangeEvent<HTMLInputElement>) => {
    if (myForm !== null) {
      setLoadingFormData(true);
      const { checked } = e.target;
      const status = checked ? 'start' : 'stop';
      try {
        const result = await apiIntance.post(`/forms/${status}`);
        if (result.status === 200) {
          setMyForm((prevState) => ({
            ...(prevState as IForm),
            active: checked,
          }));
          addToast({
            id: 'form-status-success' + new Date().toISOString(),
            type: 'success',
            text: t(
              `app.config.page.cards.customers_group.toast_toogle_status_${status}_success`,
            ),
          });
        }
      } catch (error) {
        e.target.checked = !checked;
        addToast({
          id: 'form-status-error' + new Date().toISOString(),
          type: 'danger',
          text: t(
            `app.config.page.cards.customers_group.toast_toogle_status_${status}_error`,
          ),
        });
      }
      setLoadingFormData(false);
    }
  };

  // const handleDebugMode = async (type: 'on' | 'off') => {
  //   try {
  //     const result = await apiIntance.post(`/app/debug/${type}`);
  //     addToast({
  //       id: 'debug-mode-success' + new Date().toISOString(),
  //       type: 'success',
  //       text: `Modo debug ativo na loja`,
  //     });
  //   } catch (error) {
  //     addToast({
  //       id: 'debug-mode-error' + new Date().toISOString(),
  //       type: 'danger',
  //       text: `Erro ao ativar modo debug na loja`,
  //     });
  //   }
  // };

  return (
    <>
      <Page maxWidth="800px">
        <Page.Header
          title={t('app.config.page.title')}
          buttonStack={<HelpButton />}
          // subtitle={`${t('app.config.page.subtitle')}`}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="6">
                {myForm ? (
                  <ConfigCard
                    title={`${t('app.config.page.cards.custom_form.title')}`}
                    message={t('app.config.page.cards.custom_form.message')}
                    href="/custom-form"
                    cta_text="Editar"
                    ToggleComponent={
                      <Toggle
                        name="form.active"
                        disabled={loadingFormData || myForm === null}
                        active={myForm.active}
                        onChange={handleToggleFormStatus}
                      />
                    }
                    tags={[
                      t('app.config.page.cards.custom_form.tag_qty_fields', {
                        qty: myForm.fields.length,
                      }),
                      t(
                        'app.config.page.cards.custom_form.tag_qty_custom_fields',
                        {
                          qty: getCustomFields(),
                        },
                      ),
                      myForm.popup && myForm.popup.active
                        ? t('app.config.page.cards.custom_form.tag_popup', {
                            qty: getCustomFields(),
                          })
                        : '',
                      getFormType(),
                    ]}
                  />
                ) : (
                  <></>
                )}

                {myGroupRole ? (
                  <ConfigCard
                    title={`${t(
                      'app.config.page.cards.customers_group.title',
                    )}`}
                    message={t('app.config.page.cards.customers_group.message')}
                    href="customers-group"
                    cta_text="Editar"
                    ToggleComponent={
                      <Toggle
                        name="group.active"
                        disabled={loadingGroupData || myGroupRole === null}
                        active={myGroupRole.active}
                        onChange={handleToggleGroupStatus}
                      />
                    }
                    tags={[
                      `${myGroupRole?.count_groups || 0} ${t(
                        'general.groups',
                      )}`,
                      myGroupRole?.hide_products_visitor
                        ? t(
                            'app.config.page.cards.customers_group.tag_hide_products',
                          )
                        : '',
                      myGroupRole?.hide_products_price_visitor
                        ? t(
                            'app.config.page.cards.customers_group.tag_hide_products_price',
                          )
                        : '',
                    ]}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default ConfigsPage;
