(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@nimbus-ds/components"), require("@nimbus-ds/icons"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@nimbus-ds/components", "@nimbus-ds/icons"], factory);
	else if(typeof exports === 'object')
		exports["@nimbus-ds/patterns"] = factory(require("react"), require("@nimbus-ds/components"), require("@nimbus-ds/icons"));
	else
		root["@nimbus-ds/patterns"] = factory(root["react"], root["@nimbus-ds/components"], root["@nimbus-ds/icons"]);
})(global, (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE__nimbus_ds_components__, __WEBPACK_EXTERNAL_MODULE__nimbus_ds_icons__) => {
return 